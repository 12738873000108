<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6275 16.0667C24.6275 16.4878 24.4113 17.1388 23.8692 17.9423C23.3426 18.7229 22.5704 19.5541 21.6219 20.3163C19.7096 21.8528 17.2207 23 14.8138 23C12.4068 23 9.91788 21.8528 8.00566 20.3163C7.05712 19.5541 6.28496 18.7229 5.75829 17.9423C5.21622 17.1388 5 16.4878 5 16.0667C5 15.6282 5.22905 14.8961 5.78615 13.9703C6.32556 13.0738 7.11052 12.099 8.06546 11.1983C10.0023 9.37139 12.4713 8 14.8138 8C17.1562 8 19.6252 9.37139 21.5621 11.1983C22.517 12.099 23.302 13.0738 23.8414 13.9703C24.3985 14.8961 24.6275 15.6282 24.6275 16.0667Z"
      stroke="#202329"
      stroke-width="2"
    />
    <path
      d="M18.5 15.5C18.5 17.433 16.933 19 15 19C13.067 19 11.5 17.433 11.5 15.5C11.5 13.567 13.067 12 15 12C16.933 12 18.5 13.567 18.5 15.5Z"
      stroke="#202329"
      stroke-width="2"
    />
  </svg>
</template>