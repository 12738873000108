<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.42859 6.42859L13.5714 13.5714M13.5714 6.42859L6.42859 13.5714"
      stroke="white"
      stroke-width="1.2"
    />
    <circle cx="10" cy="10" r="8.57143" fill="#CFD3D6" />
    <path
      d="M6.78577 6.78577L13.2143 13.2143M13.2143 6.78577L6.78577 13.2143"
      stroke="white"
      stroke-width="1.2"
    />
  </svg>
</template>
