<template>
  <div class="customize-input-password">
    <slot name="default" :showPassword="showPassword"></slot>
    <div class="hidden-password-icon" v-if="showPassword">
      <ShowPasswordIcon @click.stop="changeShowType" />
    </div>
    <div class="hidden-password-icon" v-else>
      <HiddenPasswordIcon @click.stop="changeShowType" />
    </div>
    <div class="input-clear-icon" v-if="modelValue && showClear">
      <InputClearIcon @click.stop="clearInput" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { nextTick,onMounted ,ref} from 'vue';

const emits = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  showClear: {
    type: Boolean,
    default: false,
  },
})

let showPassword = ref(false);

const changeShowType = () => {
  showPassword.value = !showPassword.value;
};

const clearInput = () => {
  emits("update:modelValue", "");
};

onMounted(() => {
  nextTick(() => {
    showPassword.value = false;
  })
});

</script>
<style lang="scss" scoped>
.customize-input-password{
  height: 100%;
  width: 100%;
  position: relative;
  .hidden-password-icon{
    height: 24px;
    width: 24px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    svg{
      height: 100%;
      width: 100%;
    }
  }
  .input-clear-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 58px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    svg{
      height: 100%;
      width: 100%;
    }
  }
}
</style>
