<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.6275 16.0667C23.6275 16.2006 23.533 16.6526 23.0402 17.383C22.5783 18.0677 21.8788 18.827 20.9955 19.5368C20.6657 19.8018 20.3198 20.0525 19.9618 20.2852L21.3507 21.7622C23.8671 20.0381 25.6275 17.6834 25.6275 16.0667C25.6275 13.2333 20.2206 7 14.8138 7C12.8903 7 10.9669 7.78883 9.28684 8.93282L10.6764 10.4106C12.0402 9.53343 13.484 9 14.8138 9C16.7953 9 19.0298 10.1845 20.8759 11.9257C21.772 12.771 22.4971 13.6758 22.9845 14.4858C23.5073 15.3547 23.6275 15.8981 23.6275 16.0667ZM17.1135 21.6355C16.3455 21.8694 15.5691 22 14.8138 22C12.7034 22 10.4289 20.9805 8.63202 19.5368C7.74872 18.827 7.04923 18.0677 6.58727 17.383C6.09451 16.6526 6 16.2006 6 16.0667C6 15.8981 6.12017 15.3547 6.64299 14.4858C7.05574 13.7999 7.63889 13.046 8.35196 12.3179L6.98087 10.8598C5.17658 12.6834 4 14.745 4 16.0667C4 18.9 9.40688 24 14.8138 24C16.1042 24 17.3946 23.7095 18.6116 23.2286L17.1135 21.6355Z"
      fill="#CFD3D6"
    />
    <path
      d="M8.19678 8.46375L21.8204 22.9519"
      stroke="#CFD3D6"
      stroke-width="2"
    />
  </svg>
</template>
